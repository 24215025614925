import { Controller } from "@hotwired/stimulus";
import TurboRequest from "../helpers/turbo-request";
import { getUrlWithParams } from "../helpers/request-formatter";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    previousAudienceType: { type: String, default: 'mixed' },
  };

  connect() {
    const audienceTypeElement = this.element.querySelector("#audience_type");
    
    const textAudienceTypeElement = audienceTypeElement.querySelector(
      '[data-dropdown-input-target="text"]'
    );

    if (textAudienceTypeElement) {
      this.previousAudienceTypeValue = textAudienceTypeElement.innerHTML
        .trim()
        .toLowerCase();
    }
  }

  async replaceRewardSelection(event) {
    event.preventDefault();
    const audienceTypeKey = event.target.dataset.audienceTypeKey;
    const urlAction = event.target.dataset.urlAction;

    if (this.previousAudienceTypeValue === audienceTypeKey) {
      return;
    }

    this.previousAudienceTypeValue = audienceTypeKey;

    const url = getUrlWithParams(urlAction, [
      ["audience_type", audienceTypeKey],
    ]);

    await TurboRequest.get(url);
  }
 
}
